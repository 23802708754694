<template>
  <div v-if="isLoading" class="text-center">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-4">
    <asom-alert class="col-span-2" v-if="error" variant="error" :error-message="error" />
    <table class="AsomTable AsomTable--striped">
      <caption class="hidden"></caption>
      <thead>
        <tr>
          <th scope="col" class="w-12">No</th>
          <th scope="col" class="w-2/3">Setting</th>
          <th scope="col">Value</th>
          <th scope="col" class="w-32">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Inactivity before logout (in minutes)</td>
          <td>{{ configData.inactivityBeforeLogout }}</td>
          <td>
            <asom-button text="Edit" @click="openModalForLogoutDuration" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <asom-modal
    v-model="modal.show"
    dismissible
    title="Update Configuration"
  >
  <asom-alert class="col-span-2" v-if="modalError" variant="error" :error-message="modalError" />
  <asom-form-field 
    :label="modal.label" 
    required
    >
    <asom-input-text type="number" min="0" v-model="modal.value" />
    </asom-form-field>
    <div class="flex flex-row-reverse pt-4">
      <div class="pl-4">
        <asom-button
          @click="submit"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="Update"
        />
      </div>
      <div>
        <asom-button
          @click="closeModal"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </div>
  </asom-modal>
</template>
<script>
import get from 'lodash.get'
import { getAsync, postAsync } from '@/services/serviceHandlers';
import {
  LIST_USER_MGNT_CONFIG,
  EDIT_DURATION_LOGOUT,
  EDIT_DURATION_SUSPENSE,
  EDIT_NO_LOGIN_ATTEMPT,
} from '@/constants/apis';
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, minValue } from "@vuelidate/validators";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      configData: {
        inactivityBeforeLogout: 0,
        numberOfLoginAttempts: 0,
        inactivityBeforeSuspend: 0,
      },
      modal: {
        show: false,
        label: '',
        value: 0,
        target: '',
      },
      isLoading: false,
      error: null,
      modalError: null,
      isSubmitting: false,
    };
  },
  validations() {
    return {
      modal: {
        value: { required, numeric, minValue: minValue(0) },
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const resp = await getAsync({ url: LIST_USER_MGNT_CONFIG });
      if (resp.success) {
        this.error = null;
        this.configData.inactivityBeforeLogout = get(resp, 'payload.inactivePeriodForLogout', 0);
        this.configData.numberOfLoginAttempts = get(resp, 'payload.numOfLoginAttempts', 0);
        this.configData.inactivityBeforeSuspend = get(resp, 'payload.inactivePeriodForSuspense', 0);
      } else {
        this.error = resp.payload;
      }
      this.isLoading = false;
    },
    openModal() {
      this.modal.show = true;
    },
    closeModal() {
      this.modal.show = false;
    },
    openModalForLogoutDuration() {
      this.modal.value = this.configData.inactivityBeforeLogout;
      this.modal.label = 'Inactivity before logout (in minutes)';
      this.modal.target = EDIT_DURATION_LOGOUT;
      this.openModal();
    },
    openModalForLoginAttempt() {
      this.modal.value = this.configData.numberOfLoginAttempts;
      this.modal.label = 'Number of login attempts before auto-suspension';
      this.modal.target = EDIT_NO_LOGIN_ATTEMPT;
      this.openModal();
    },
    openModalForSuspenseDuration() {
      this.modal.value = this.configData.inactivityBeforeSuspend;
      this.modal.label = 'Period of inactivity before auto-suspension';
      this.modal.target = EDIT_DURATION_SUSPENSE;
      this.openModal();
    },
    async submit() {
      this.isSubmitting = true;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.modalError = "Please complete all required fields";
      } else {
        const resp = await postAsync({
          url: this.modal.target,
          body: {
            value: this.modal.value
          }
        });
        if (resp.success) {
          this.error = null;
          this.afterSubmit();
        } else {
          this.error = resp.payload;
          this.modal.show = false;
        }
      }
      this.isSubmitting = false;
    },
    afterSubmit() {
      this.modal.show = false;
      this.loadData();
    }
  }
}
</script>
<template>
  <asom-card>
    <div v-if="isLoadingProfile" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <form v-else class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
      <asom-alert class="col-span-2" v-if="error" variant="error" :error-message="error" />
      <asom-form-field
        label="SAP ID"
        :state="inputStates('personNo')"
        error="SAP ID is required"
      >
        <asom-input-text
          v-model="personNo"
          :state="inputStates('personNo')"
          placeholder="Enter valid SAP ID"
        />
      </asom-form-field>
      <asom-form-field
        label="AD Username"
        :state="inputStates('username')"
        error="AD Username is required"
      >
      <asom-input-text
          v-model="username"
          :state="inputStates('username')"
          placeholder="Enter valid username"
        />
      </asom-form-field>
      <asom-form-field
        label="Name"
        :state="inputStates('name')"
        error="Name is required"
      >
        <asom-input-text
          v-model="name"
          :state="inputStates('name')"
          placeholder="Enter valid Name"
        />
      </asom-form-field>
      <asom-form-field
        label="Email"
        :state="inputStates('email')"
        error="Email is required"
      >
        <asom-input-text
          v-model="email"
          :state="inputStates('email')"
          placeholder="Enter valid email"
        />
      </asom-form-field>
      <asom-form-field
        label="MRT Line"
        required
        :state="inputStates('lineId')"
        error="MRT Line is required"
      >
        <asom-input-select 
          disabled
          :objectModelValue="false"
          v-model="lineId" 
          :options="lineOptions"
          placeholder="Select MRT line"
          :state="inputStates('lineId')"
        />
      </asom-form-field>
      <asom-form-field label="MRT Station"
        required
        :state="inputStates('stationId')"
        error="MRT Station is required"
      >
        <asom-input-select
          disabled
          :objectModelValue="false"
          v-model="stationId"
          :options="stationOptions"
          placeholder="Select MRT station"
          :state="inputStates('stationId')"
        />
      </asom-form-field>
      <!-- <asom-form-field label="DateEffectiveFrom"
        required
        :state="inputStates('dateEffectiveFrom')"
        error="DateEffectiveFrom is required"
      >
      <asom-input-date-time v-model="dateEffectiveFrom"/>
      </asom-form-field> -->
      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button text="Update Details" @click="onSubmit" :loading="isSubmitting" />
        <asom-button text="Cancel" variant="secondary" @click="$router.push('/users')" />
      </div> 
    </form>
  </asom-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import get from 'lodash.get'

import { getUserDetails, editUser } from '../../services/user.service'
import inputStates from '@/mixins/inputStates'
import { useVuelidate } from '@vuelidate/core'
// import { parseDateTimeToUtc, displayUtcDateTime } from "@/helpers/dateTimeHelpers";

export default {
  name: 'EditUserDetails',
  mixins: [ inputStates ],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      officerId: get(this, '$route.params.id'),
      name: '',
      personNo: "",
      username: "",
      email: '',
      lineId: '',
      stationId: '',
      // dateEffectiveFrom:null,
      isLoadingProfile: false,
      isSubmitting: false,
      error: '',
    }
  },
  validations() {
    return {
      officerId: { required },
      lineId: { required },
      stationId: { required },
      personNo: { required },
      username: { required },
      // dateEffectiveFrom: { required }
    }
  },
  mounted() {
    this.getUserDetails()
    if (this.v$) {
      this.v$.$reset()
    }
  },
  computed: {
    ...mapGetters({
      lineOptions: 'smrtResource/lineOptions',
    }),
    stationOptions() {
      if (!this.lineId)
        return this.$store.getters['smrtResource/stationOptions']
      else
        return this.$store.getters['smrtResource/stationOptionsByLineId'](this.lineId)
    },
  },
  methods: {
    setUserDetails({ name, email, personNo, username, line, station }) {
      this.name = name || ""
      this.email = email || ""
      this.personNo = personNo || ""
      this.username = username || ""
      
      if (typeof line === 'string') {
        const _line = this.lineOptions.find(r => r.label === line)
        if (_line) {
          this.lineId = _line.lineId
        }
      } else
        this.lineId = line.lineId
      
      if (typeof station === 'string') {
        const _station = this.stationOptions.find(r => r.name === station)
        if (_station) {
          this.stationId = _station.stationId
        }
      } else {
        this.stationId = station.stationId
        // this.dateEffectiveFrom = new Date(displayUtcDateTime(station.dateEffectiveFrom, "YYYY-MM-DD HH:mm:ss"));
      }

      return false
    },
    async getUserDetails() {
      this.isLoadingProfile = true
      const result = await getUserDetails(this.officerId)
      if (result.success) {
        this.setUserDetails(get(result, 'payload.data', {}))
      } else {
        this.error = result.payload
      }
      this.isLoadingProfile = false
    },
    async onSubmit() {
      if (this.isSubmitting)
        return;
      this.error = ''
      this.v$.$reset()
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const result = await editUser({
          officerId: this.officerId,
          stationId: this.stationId,
          lineId: this.lineId,
          name: this.name,
          email: this.email,
          personNo: this.personNo,
          username: this.username,
          // dateEffectiveFrom: parseDateTimeToUtc(this.dateEffectiveFrom)
        })
       
        this.isSubmitting = false
        if (result.success)
          this.$router.push('/users')
        else
          this.error = result.payload
      } else {
        this.error = 'Please complete all required fields'
      }
    }
  },
}
</script>

<template>
  <div v-if="isLoading" class="text-center">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-4">
    <asom-alert class="col-span-2" v-if="error" variant="error" :error-message="error" />
    <asom-client-table
      :columns="[ 'no', 'userName', 'action', 'dateCreated', 'isSucceeded', 'message']"
      :data="data"
    >
      <template v-slot:header_dateCreated>
        Date
      </template>
      <template v-slot:header_isSucceeded>
        Success
      </template>
      <template v-slot:no="slotScoped">
        {{slotScoped.rowIndex + 1}}
      </template>
      <template v-slot:dateCreated="slotScoped">{{
          displayUtcDateTime(slotScoped.data)
        }}</template>
    </asom-client-table>
  </div>
</template>
<script>
import get from 'lodash.get'
import { getAsync } from '@/services/serviceHandlers';
import { displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import {
  LIST_USER_ACCESS_LOGS,
} from '@/constants/apis';

export default {
  data() {
    return {
      isLoading: false,
      error: null,
      data: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    displayUtcDateTime,
    async loadData() {
      this.isLoading = true;
      const result = await getAsync({
        url: LIST_USER_ACCESS_LOGS,
        params: {
          skip: 0,
          take: 100,
        }
      });
      if (result.success) {
        this.error = null;
        this.data = get(result, 'payload.list', []);
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    }
  }
}
</script>